import React, { useReducer, createContext } from 'react'
import { ILocation } from 'lib/get-location'

interface IBrandItem {
  id: number
  name: string
  avatar: string
}

interface IUserData {
  /**
   * 性别
   */
  gender: string

  /**
   * 用户名
   */
  name: string

  /**
   * 用户名
   */
  screen_name: string

  /**
   * 用户ID
   */
  user_id: string

  /**
   * 用户类型
   */
  user_type: string

  /**
   * 头像
   */
  avatar_url: string
}

interface IStore {
  /**
   *  城市名称
   */
  cityName?: string

  /**
   * 城市列表
   */
  cities: {
    initials: string
    city: {
      city_name: string
      initials: string
    }[]
  }[]

  /**
   * 品牌列表
   */
  brands: {
    /**
     * 所有的字母列表
     */
    letters: string[]
    list: {
      letter: string
      data: IBrandItem[]
    }[]
  }

  /**
   * 经纬度等坐标
   */
  location: ILocation

  /**
   * 页面可见状态，默认为true
   */
  isVisible: boolean

  /**
   * 用户信息
   */
  userData: IUserData
}
interface ISetCityName {
  type: 'setCityName'
  payload?: IStore['cityName']
}

interface ISetCities {
  type: 'setCities'
  payload?: IStore['cities']
}

interface ISetBrandList {
  type: 'setBrands'
  payload?: IStore['brands']
}

interface ISetLocation {
  type: 'setLocation'
  payload?: IStore['location']
}

interface ISetUserData {
  type: 'setUserData'
  payload?: IStore['userData']
}

type IAction =
  | ISetCityName
  | ISetCities
  | ISetBrandList
  | ISetLocation
  | ISetUserData

interface StoreContext {
  state: Partial<IStore>
  dispatch(param: ISetCityName): void
  dispatch(param: ISetCities): void
  dispatch(param: ISetBrandList): void
  dispatch(param: ISetLocation): void
  dispatch(param: ISetUserData): void
}
const initState = {
  cityName: undefined,
  cities: undefined,
  brands: undefined,
  location: undefined,
  userData: undefined,
}
const GlobalStoreContext = createContext<StoreContext>({
  state: initState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
})

function reducer(state: Partial<IStore> = initState, action: IAction) {
  switch (action.type) {
  case 'setLocation':
    return {
      ...state,
      location: action.payload,
    }
  case 'setCityName':
    try {
      window.localStorage.setItem('cityName', action.payload || '')
    } catch (err) {}
    return {
      ...state,
      cityName: action.payload,
    }
  case 'setCities':
    return {
      ...state,
      cities: action.payload,
    }
  case 'setBrands':
    return {
      ...state,
      brands: action.payload,
    }
  case 'setUserData':
    return {
      ...state,
      userData: action.payload,
    }
  default:
    return state
  }
}

const GlobalStoreProvider = (props: {
  children: React.ReactNode
  value: Partial<IStore>
}) => {
  const { children, value } = props
  const [state, dispatch] = useReducer(reducer, initState)
  return (
    <GlobalStoreContext.Provider
      value={{
        state: {
          ...state,
          ...value,
        },
        dispatch,
      }}
    >
      {children}
    </GlobalStoreContext.Provider>
  )
}

export { GlobalStoreProvider, GlobalStoreContext }
